var __WEBPACK_NAMESPACE_OBJECT__ = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "page-editor-ui": "static-1.27175",
      "apollo-dlb": "static-3.41",
      "backbone": "static-1.6",
      "codemirror-dlb": "static-1.48613",
      "collaboration-sidebar": "static-1.47487",
      "ContentComponents": "static-2.62233",
      "ContentData": "static-1.59351",
      "ContentEditorUI": "static-2.67063",
      "ContentUtils": "static-1.59273",
      "data-fetching-client": "static-1.6673",
      "editor-components": "static-1.9958",
      "emoji-mart": "static-3.4",
      "FileManagerLib": "static-1.30265",
      "foundations-assets": "static-1.2224",
      "foundations-components": "static-1.4115",
      "head-dlb": "static-1.2378",
      "hs-test-utils": "static-1.5455",
      "hub-http": "static-1.2732",
      "hub-http-shared-msw-handlers": "static-1.5295",
      "hubspot-dlb": "static-2.439",
      "HubStyleTokens": "static-2.9005",
      "I18n": "static-7-dc-cleanup-postmodern.3",
      "InpageEditorUI": "static-1.75511",
      "jasmine-immutable": "static-1.355",
      "jasmine-runner": "static-1.3827",
      "layout-dnd-utils": "static-1.7891",
      "marketing-discovery-utils": "static-1.788",
      "quartz": "static-1.3978",
      "react-dlb": "static-1.42",
      "react-dnd": "static-4.38",
      "react-dnd-html5-backend": "static-4.5",
      "react-redux": "static-7.16",
      "react-router-dom": "static-5.26",
      "react-utils": "static-2.3142",
      "redux-thunk": "static-2.17",
      "settings-ui-lib": "static-1.9806",
      "sinon": "static-1.9",
      "tinymce": "static-6.5",
      "ui-addon-draggable": "static-1.4353",
      "ui-components-test-utils": "static-1.3146",
      "ui-images": "static-2.765",
      "ui-memberships-settings-lib": "static-1.23152",
      "webpack-env": "static-1.41",
      "ai-components-ui-library": "static-1.3418",
      "apollo-stack-hubspot": "static-3.43",
      "atom": "static-1.2843",
      "blueimp-md5": "static-1.8",
      "campaigns-lib": "static-1.18946",
      "classnames": "static-2.10",
      "content-assistance-lib": "static-1.4492",
      "content-management-data-lib": "static-1.2441",
      "copilot-plugins": "static-1.2411",
      "copilot-toolkit": "static-1.2437",
      "cropperjs": "static-1.10",
      "cssUtils": "static-1.320",
      "cta-picker": "static-1.27972",
      "customer-data-objects": "static-1.4972",
      "customer-data-objects-ui-components": "static-1.10124",
      "customer-data-properties": "static-1.41176",
      "customer-data-property-utils": "static-1.5160",
      "customer-data-reference-ui-components": "static-1.10220",
      "customer-data-ui-utilities": "static-1.7171",
      "data-token-picker": "static-1.11960",
      "data-token-views": "static-1.11944",
      "error-messaging-js": "static-1.2778",
      "feedback-loader": "static-1.24171",
      "file-manager-components": "static-1.14622",
      "FileManagerCore": "static-1.30122",
      "FormsCommonComponents": "static-1.77129",
      "foundations-theming": "static-1.1326",
      "fuzzy": "static-1.8",
      "growth-onboarding-confetti": "static-1.580",
      "growth-payments-core": "static-1.17551",
      "history": "static-4.15",
      "hs-lodash": "static-4.9",
      "hs-promise-utils": "static-1.2879",
      "hub-http-contrib": "static-1.654",
      "hubspot-url-utils": "static-1.1893",
      "HubStyle": "static-2.9315",
      "icons": "static-2.585",
      "immer": "static-1.33",
      "immutable": "static-2.19",
      "InboxConnectUtils": "static-4.8122",
      "integrations-lib": "static-1.7941",
      "interframe": "static-3.1994",
      "laboratory-lib": "static-3.4512",
      "layout-data-lib": "static-1.6216",
      "media-bridge-lib": "static-1.14579",
      "nav-meta": "static-1.23298",
      "object-builder-ui-client": "static-1.36176",
      "onboarding-tours": "static-1.11729",
      "onboarding-tours-client": "static-1.1974",
      "outpost": "static-1.1791",
      "PatternValidationJS": "static-1.437",
      "payment-link-components": "static-1.14198",
      "payments-post-enroll-local-storage-lib": "static-1.2454",
      "pictos": "static-7.150",
      "PortalIdParser": "static-2.250",
      "products-ui-components": "static-1.32034",
      "property-translator": "static-1.2894",
      "quick-fetch": "static-1.1826",
      "raven": "static-3.3055",
      "react": "static-7.133",
      "react-beautiful-dnd": "static-1.13",
      "react-colorpicker": "static-2.7596",
      "react-dom": "static-7.85",
      "react-flip-move": "static-1.39",
      "react-immutable-proptypes": "static-2.8",
      "react-input-autosize": "static-2.17",
      "react-rhumb": "static-1.13925",
      "react-select-plus": "static-1.65",
      "react-tinymce": "static-1.46704",
      "react-transition-group": "static-1.7",
      "react-virtualized": "static-2.66",
      "redux": "static-4.16",
      "redux-request-state": "static-1.7812",
      "reference-resolvers": "static-1.5701",
      "reference-resolvers-lite": "static-1.5271",
      "reselect": "static-2.16",
      "rich-text-lib": "static-1.15425",
      "sanitize": "static-1.6",
      "sanitize-text": "static-1.5613",
      "seamless-immutable": "static-7.6",
      "self-service-api": "static-1.7518",
      "SharedI18nStrings": "static-1.160",
      "SharedLegalStrings": "static-1.1230",
      "simple-menus-core": "static-1.11646",
      "simple-menus-lib": "static-1.13164",
      "smart-content-lib": "static-1.33844",
      "styled-components": "static-2.40",
      "StyleGuideUI": "static-3.432",
      "superstore": "static-1.2173",
      "theme-previewer-lib": "static-1.42776",
      "tinymce-config": "static-2.45831",
      "tinymce-copilot": "static-1.7899",
      "tinymce-data": "static-1.31913",
      "tinymce-plugins": "static-1.48944",
      "tinymce-themes": "static-1.47798",
      "transmute": "static-2.29",
      "ui-addon-avatars": "static-2.6962",
      "ui-addon-emoji-picker": "static-1.7294",
      "ui-addon-form-validation": "static-1.8403",
      "ui-addon-i18n": "static-1.8201",
      "ui-addon-iframeable": "static-1.6477",
      "ui-addon-opt": "static-4.6147",
      "ui-addon-sales-email": "static-1.2664",
      "ui-addon-upgrades": "static-9.14185",
      "ui-business-units-lib": "static-1.6048",
      "ui-gdpr-components": "static-1.7588",
      "ui-product-approvals": "static-1.20896",
      "ui-shepherd-react": "static-3.7317",
      "ui-suspension-banners-lib": "static-1.3818",
      "UIComponents": "static-3.5955",
      "underscore": "static-1.8",
      "urlinator": "static-1.2051",
      "usage-tracker": "static-1.4974",
      "usage-tracker-core": "static-1.4824",
      "user-context": "static-1.2489",
      "video-embed": "static-1.26304",
      "web-interactives-components": "static-1.9448",
      "web-interactives-data": "static-1.9448",
      "wootric-nps": "static-1.4425",
      "xray-recommendation-lib": "static-1.4154",
      "codemirror": "static-5.61",
      "react-codemirror": "static-1.7944",
      "enviro": "static-4.271",
      "hs-promise-rejection-tracking": "static-1.2751",
      "raven-hubspot": "static-1.3362",
      "hoist-non-react-statics": "static-3.9",
      "metrics-js": "static-1.6916",
      "bend-plugin-trellis-migration": "static-1.1031",
      "moment": "static-3.26",
      "jquery": "static-3.6",
      "chatspot-client-types": "static-1.6548",
      "chatspot-core": "static-1.11493",
      "chatspot-widget-iframe": "static-1.11491",
      "framework-data-schema-resolvers": "static-1.3992",
      "frontend-preferences-client": "static-1.5264",
      "graphql": "static-1.50",
      "cms-field-types": "static-1.2202",
      "content-embed-lib": "static-1.4310",
      "ExportDialog": "static-6.7628",
      "forms-utils-lib": "static-1.8088",
      "integrations-error-boundary-lib": "static-1.5354",
      "payments-embed-points-lib": "static-1.6259",
      "react-dnd-compat": "static-1.1850",
      "usage-tracker-container": "static-1.4964",
      "video-data-lib": "static-1.25461",
      "videoconference-integration-components": "static-1.3259",
      "editor-iframe-lib": "static-1.5585",
      "FireAlarmUi": "static-1.2677",
      "hubspot-apollo-client": "static-1.2154",
      "layout-dnd-components": "static-1.8916",
      "marketing-email-service-types": "static-1.888",
      "quartz-core": "static-1.3197",
      "rpc-client-utils": "static-1.2072",
      "smart-content-core": "static-1.33189",
      "social-composer-lib": "static-1.50084",
      "structured-content-lib": "static-1.15789",
      "timezone-utils": "static-2.2858",
      "ui-active-theme-content-creator-lib": "static-1.27037",
      "ui-addon-video-player": "static-1.6255",
      "ui-asset-management-lib": "static-1.7251",
      "ui-tool-access": "static-1.7278",
      "content-icons-ui": "static-1.1814",
      "FileManagerImages": "static-1.27773",
      "hs-story-utils": "static-1.6417",
      "bend-plugin-foundations-components": "static-1.383",
      "floating-ui": "static-1.19",
      "framer-motion": "static-1.29",
      "react-aria": "static-1.31",
      "tanstack-table": "static-1.10",
      "ui-fonts": "static-1.327",
      "HeadJS": "static-2.517",
      "hs-test-utils-bend-plugin": "static-1.566",
      "hub-http-janus": "static-1.585",
      "hub-http-rxjs": "static-1.550",
      "msw": "static-1.39",
      "redux-mock-store": "static-1.8",
      "testing-library": "static-1.112",
      "ts-schema": "static-1.1902",
      "i18n-data": "static-1.187",
      "moment-timezone": "static-5.17",
      "NamespacedHubStyle": "static-2.7187",
      "react-dnd-ipeui-backend": "static-1.456",
      "ui-coaching-tips": "static-1.17471",
      "jasmine": "static-4.1733",
      "quartz-core-utils": "static-1.845",
      "flux-actions": "static-1.250",
      "redux-observable": "static-1.11",
      "ReduxMessenger": "static-2.8030",
      "rxjs": "static-5.10",
      "autolinker": "static-3.11",
      "sassPrefix": "static-1.121",
      "campaigns-build-lib": "static-1.2068",
      "crm-pipelines-api-client-types": "static-1.1801",
      "marketing-platform-lib": "static-1.3971",
      "ai-rendering-library": "static-1.1754",
      "commerce-products-lib": "static-1.1510",
      "conditional-properties-service-client": "static-1.1970",
      "crm-message-bus": "static-1.31352",
      "external-options-client-types": "static-1.1907",
      "google-libphonenumber": "static-1.3",
      "inbound-db-properties-service-client": "static-1.1926",
      "inbounddb-objects-service-types": "static-1.1851",
      "pipeline-validation-client-types": "static-1.1868",
      "react-immutable-render-mixin": "static-1.9",
      "salesImages": "static-1.489",
      "foundations-theming-base": "static-1.311",
      "commerce-analytics-service-client": "static-1.1455",
      "native-integrations-shared-strings": "static-1.5193",
      "getting-started-shared-tasks": "static-1.7239",
      "growth-data-modal": "static-1.2383",
      "ui-shepherd-tracker": "static-1.4698",
      "ui-universal-auth": "static-1.6008",
      "commerce-tours-lib": "static-1.1328",
      "growth-onboarding-reliability": "static-1.2497",
      "sales-checkout-service-client": "static-1.1467",
      "commerce-billing-lib": "static-1.10975",
      "commerce-shared-components-lib": "static-1.11510",
      "crm_components": "static-3.82377",
      "crm_data": "static-4.82377",
      "customer-data-filters-ui": "static-1.29653",
      "customer-data-tracking": "static-1.4248",
      "dispatcher": "static-1.104",
      "framework-data-table": "static-2.5917",
      "property-description-translator": "static-1.1367",
      "csstype": "static-1.8",
      "fast-json-stable-stringify": "static-1.4",
      "redux-undo": "static-1.10",
      "hubspotter-http": "static-1.2495",
      "feature-store-service-types": "static-1.2312",
      "growth-monetization-service-types": "static-1.2319",
      "upgrade-management-service-types": "static-1.2303",
      "permission-components": "static-1.2730",
      "getting-started-shared-tasks-store": "static-1.6357",
      "diff-match-patch": "static-1.7",
      "browser-eslint": "static-2.9",
      "framework-builder-read-service-client": "static-1.1888",
      "framework-data-schema-quick-fetch": "static-1.3896",
      "inbound-db-meta-service-client": "static-1.1587",
      "persist-promise": "static-1.1223",
      "payments-enrollment-embed-lib": "static-1.13737",
      "apollo-link-hub-http": "static-2.2160",
      "general-store": "static-6.20",
      "hubspotter-http-shared-msw-handlers": "static-1.5293",
      "mobile-manifest-mixins": "static-1.288",
      "platform-navigation-bootstrap": "static-1.9214",
      "ui-addon-react-router-dom": "static-1.6995",
      "growth-onboarding-feedback-components": "static-1.3848",
      "customer-data-filters": "static-1.43466",
      "highlight.js": "static-1.5",
      "markdown-it": "static-1.6",
      "react-table": "static-1.15",
      "reporting-action-components": "static-1.32188",
      "reporting-ui-components": "static-2.54976",
      "reporting-visualizations": "static-1.49771",
      "ui-addon-teams": "static-1.17874",
      "commerce-tools-ui-lib": "static-1.2933",
      "usage-tracker-session-replay": "static-1.2660",
      "conversations-skeleton-state": "static-1.4793",
      "crm_universal": "static-1.18997",
      "customer-data-content": "static-1.22137",
      "reporting-data": "static-1.57690",
      "crm_schema": "static-3.82412",
      "customer-data-email": "static-1.13870",
      "customer-data-filters-ui-msw-helpers": "static-1.12084",
      "FormUrlGenerator": "static-2.2192",
      "lodash": "static-4.7",
      "crm-links": "static-1.3365",
      "customer-data-associations": "static-1.4675",
      "redux-toolkit": "static-1.7",
      "ecosystem-marketplace-core": "static-1.14427",
      "stripe-embedded-components": "static-1.3256",
      "platform-infra-nav-components": "static-1.5594",
      "deep-diff": "static-1.5",
      "dashboard-lib": "static-1.71289",
      "reporting-snowflake": "static-1.46317",
      "share-with-third-party-component-lib": "static-1.5869",
      "highcharts": "static-8.69",
      "reporting-plugins": "static-1.4567",
      "reporting-reports": "static-1.57380",
      "customer-data-rte": "static-1.25687",
      "cv-backend-services": "static-1.851",
      "hubspot-prosemirror": "static-1.10148",
      "prosemirror": "static-1.13",
      "qrcode-generator": "static-1.36",
      "amplitude-session-replay-browser": "static-1.6",
      "SalesContentIndexUI": "static-3.4909",
      "sequences-lib": "static-1.3818",
      "reporting-client-types": "static-1.1152",
      "location-customization-utils-lib": "static-1.342",
      "immutable-less": "static-1.2053",
      "marketplace-ui-apps-core": "static-1.9012",
      "marketplace-ui-assets-core": "static-1.10418",
      "marketplace-ui-common": "static-1.10474",
      "marketplace-ui-reviews": "static-1.10436",
      "marketplace-ui-solutions-core": "static-1.10449",
      "signup-constants": "static-1.13873",
      "signup-ui-url-generator": "static-2.5329",
      "stripe-connect-js": "static-1.30",
      "onboarding-scopes": "static-1.3819",
      "redux-actions": "static-3.7",
      "reporting-datasets-permissions-lib": "static-1.3113",
      "draft-content-plugins": "static-1.20972",
      "draft-convert": "static-2.31",
      "draft-extend": "static-2.46",
      "draft-js": "static-5.37",
      "draft-plugins": "static-2.17257",
      "knowledge-content-types": "static-1.6833",
      "sequences-client-types-lib": "static-1.3674",
      "crm-record-cards-service-types": "static-1.1665",
      "developer-experience-shared-components": "static-1.7211",
      "marketplace-ui-client-types": "static-1.7878",
      "ui-addon-integrations-directory-panel": "static-2.5337",
      "ui-extensibility-client-types": "static-1.1901",
      "react-window": "static-1.13",
      "trellis-ecosystem": "static-1.2667",
      "apps-service-types": "static-1.2093",
      "oauth-service-types": "static-1.1884",
      "trellis-story-utils": "static-1.1914"
    },
    "depPathPrefixes": {
      "page-editor-ui": "/page-editor-ui/static-1.27175",
      "apollo-dlb": "/apollo-dlb/static-3.41",
      "backbone": "/backbone/static-1.6",
      "codemirror-dlb": "/codemirror-dlb/static-1.48613",
      "collaboration-sidebar": "/collaboration-sidebar/static-1.47487",
      "ContentComponents": "/ContentComponents/static-2.62233",
      "ContentData": "/ContentData/static-1.59351",
      "ContentEditorUI": "/ContentEditorUI/static-2.67063",
      "ContentUtils": "/ContentUtils/static-1.59273",
      "data-fetching-client": "/data-fetching-client/static-1.6673",
      "editor-components": "/editor-components/static-1.9958",
      "emoji-mart": "/emoji-mart/static-3.4",
      "FileManagerLib": "/FileManagerLib/static-1.30265",
      "foundations-assets": "/foundations-assets/static-1.2224",
      "foundations-components": "/foundations-components/static-1.4115",
      "head-dlb": "/head-dlb/static-1.2378",
      "hs-test-utils": "/hs-test-utils/static-1.5455",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-shared-msw-handlers": "/hub-http-shared-msw-handlers/static-1.5295",
      "hubspot-dlb": "/hubspot-dlb/static-2.439",
      "HubStyleTokens": "/HubStyleTokens/static-2.9005",
      "I18n": "/I18n/static-7-dc-cleanup-postmodern.3",
      "InpageEditorUI": "/InpageEditorUI/static-1.75511",
      "jasmine-immutable": "/jasmine-immutable/static-1.355",
      "jasmine-runner": "/jasmine-runner/static-1.3827",
      "layout-dnd-utils": "/layout-dnd-utils/static-1.7891",
      "marketing-discovery-utils": "/marketing-discovery-utils/static-1.788",
      "quartz": "/quartz/static-1.3978",
      "react-dlb": "/react-dlb/static-1.42",
      "react-dnd": "/react-dnd/static-4.38",
      "react-dnd-html5-backend": "/react-dnd-html5-backend/static-4.5",
      "react-redux": "/react-redux/static-7.16",
      "react-router-dom": "/react-router-dom/static-5.26",
      "react-utils": "/react-utils/static-2.3142",
      "redux-thunk": "/redux-thunk/static-2.17",
      "settings-ui-lib": "/settings-ui-lib/static-1.9806",
      "sinon": "/sinon/static-1.9",
      "tinymce": "/tinymce/static-6.5",
      "ui-addon-draggable": "/ui-addon-draggable/static-1.4353",
      "ui-components-test-utils": "/ui-components-test-utils/static-1.3146",
      "ui-images": "/ui-images/static-2.765",
      "ui-memberships-settings-lib": "/ui-memberships-settings-lib/static-1.23152",
      "webpack-env": "/webpack-env/static-1.41",
      "ai-components-ui-library": "/ai-components-ui-library/static-1.3418",
      "apollo-stack-hubspot": "/apollo-stack-hubspot/static-3.43",
      "atom": "/atom/static-1.2843",
      "blueimp-md5": "/blueimp-md5/static-1.8",
      "campaigns-lib": "/campaigns-lib/static-1.18946",
      "classnames": "/classnames/static-2.10",
      "content-assistance-lib": "/content-assistance-lib/static-1.4492",
      "content-management-data-lib": "/content-management-data-lib/static-1.2441",
      "copilot-plugins": "/copilot-plugins/static-1.2411",
      "copilot-toolkit": "/copilot-toolkit/static-1.2437",
      "cropperjs": "/cropperjs/static-1.10",
      "cssUtils": "/cssUtils/static-1.320",
      "cta-picker": "/cta-picker/static-1.27972",
      "customer-data-objects": "/customer-data-objects/static-1.4972",
      "customer-data-objects-ui-components": "/customer-data-objects-ui-components/static-1.10124",
      "customer-data-properties": "/customer-data-properties/static-1.41176",
      "customer-data-property-utils": "/customer-data-property-utils/static-1.5160",
      "customer-data-reference-ui-components": "/customer-data-reference-ui-components/static-1.10220",
      "customer-data-ui-utilities": "/customer-data-ui-utilities/static-1.7171",
      "data-token-picker": "/data-token-picker/static-1.11960",
      "data-token-views": "/data-token-views/static-1.11944",
      "error-messaging-js": "/error-messaging-js/static-1.2778",
      "feedback-loader": "/feedback-loader/static-1.24171",
      "file-manager-components": "/file-manager-components/static-1.14622",
      "FileManagerCore": "/FileManagerCore/static-1.30122",
      "FormsCommonComponents": "/FormsCommonComponents/static-1.77129",
      "foundations-theming": "/foundations-theming/static-1.1326",
      "fuzzy": "/fuzzy/static-1.8",
      "growth-onboarding-confetti": "/growth-onboarding-confetti/static-1.580",
      "growth-payments-core": "/growth-payments-core/static-1.17551",
      "history": "/history/static-4.15",
      "hs-lodash": "/hs-lodash/static-4.9",
      "hs-promise-utils": "/hs-promise-utils/static-1.2879",
      "hub-http-contrib": "/hub-http-contrib/static-1.654",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1893",
      "HubStyle": "/HubStyle/static-2.9315",
      "icons": "/icons/static-2.585",
      "immer": "/immer/static-1.33",
      "immutable": "/immutable/static-2.19",
      "InboxConnectUtils": "/InboxConnectUtils/static-4.8122",
      "integrations-lib": "/integrations-lib/static-1.7941",
      "interframe": "/interframe/static-3.1994",
      "laboratory-lib": "/laboratory-lib/static-3.4512",
      "layout-data-lib": "/layout-data-lib/static-1.6216",
      "media-bridge-lib": "/media-bridge-lib/static-1.14579",
      "nav-meta": "/nav-meta/static-1.23298",
      "object-builder-ui-client": "/object-builder-ui-client/static-1.36176",
      "onboarding-tours": "/onboarding-tours/static-1.11729",
      "onboarding-tours-client": "/onboarding-tours-client/static-1.1974",
      "outpost": "/outpost/static-1.1791",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "payment-link-components": "/payment-link-components/static-1.14198",
      "payments-post-enroll-local-storage-lib": "/payments-post-enroll-local-storage-lib/static-1.2454",
      "pictos": "/pictos/static-7.150",
      "PortalIdParser": "/PortalIdParser/static-2.250",
      "products-ui-components": "/products-ui-components/static-1.32034",
      "property-translator": "/property-translator/static-1.2894",
      "quick-fetch": "/quick-fetch/static-1.1826",
      "raven": "/raven/static-3.3055",
      "react": "/react/static-7.133",
      "react-beautiful-dnd": "/react-beautiful-dnd/static-1.13",
      "react-colorpicker": "/react-colorpicker/static-2.7596",
      "react-dom": "/react-dom/static-7.85",
      "react-flip-move": "/react-flip-move/static-1.39",
      "react-immutable-proptypes": "/react-immutable-proptypes/static-2.8",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "react-rhumb": "/react-rhumb/static-1.13925",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-tinymce": "/react-tinymce/static-1.46704",
      "react-transition-group": "/react-transition-group/static-1.7",
      "react-virtualized": "/react-virtualized/static-2.66",
      "redux": "/redux/static-4.16",
      "redux-request-state": "/redux-request-state/static-1.7812",
      "reference-resolvers": "/reference-resolvers/static-1.5701",
      "reference-resolvers-lite": "/reference-resolvers-lite/static-1.5271",
      "reselect": "/reselect/static-2.16",
      "rich-text-lib": "/rich-text-lib/static-1.15425",
      "sanitize": "/sanitize/static-1.6",
      "sanitize-text": "/sanitize-text/static-1.5613",
      "seamless-immutable": "/seamless-immutable/static-7.6",
      "self-service-api": "/self-service-api/static-1.7518",
      "SharedI18nStrings": "/SharedI18nStrings/static-1.160",
      "SharedLegalStrings": "/SharedLegalStrings/static-1.1230",
      "simple-menus-core": "/simple-menus-core/static-1.11646",
      "simple-menus-lib": "/simple-menus-lib/static-1.13164",
      "smart-content-lib": "/smart-content-lib/static-1.33844",
      "styled-components": "/styled-components/static-2.40",
      "StyleGuideUI": "/StyleGuideUI/static-3.432",
      "superstore": "/superstore/static-1.2173",
      "theme-previewer-lib": "/theme-previewer-lib/static-1.42776",
      "tinymce-config": "/tinymce-config/static-2.45831",
      "tinymce-copilot": "/tinymce-copilot/static-1.7899",
      "tinymce-data": "/tinymce-data/static-1.31913",
      "tinymce-plugins": "/tinymce-plugins/static-1.48944",
      "tinymce-themes": "/tinymce-themes/static-1.47798",
      "transmute": "/transmute/static-2.29",
      "ui-addon-avatars": "/ui-addon-avatars/static-2.6962",
      "ui-addon-emoji-picker": "/ui-addon-emoji-picker/static-1.7294",
      "ui-addon-form-validation": "/ui-addon-form-validation/static-1.8403",
      "ui-addon-i18n": "/ui-addon-i18n/static-1.8201",
      "ui-addon-iframeable": "/ui-addon-iframeable/static-1.6477",
      "ui-addon-opt": "/ui-addon-opt/static-4.6147",
      "ui-addon-sales-email": "/ui-addon-sales-email/static-1.2664",
      "ui-addon-upgrades": "/ui-addon-upgrades/static-9.14185",
      "ui-business-units-lib": "/ui-business-units-lib/static-1.6048",
      "ui-gdpr-components": "/ui-gdpr-components/static-1.7588",
      "ui-product-approvals": "/ui-product-approvals/static-1.20896",
      "ui-shepherd-react": "/ui-shepherd-react/static-3.7317",
      "ui-suspension-banners-lib": "/ui-suspension-banners-lib/static-1.3818",
      "UIComponents": "/UIComponents/static-3.5955",
      "underscore": "/underscore/static-1.8",
      "urlinator": "/urlinator/static-1.2051",
      "usage-tracker": "/usage-tracker/static-1.4974",
      "usage-tracker-core": "/usage-tracker-core/static-1.4824",
      "user-context": "/user-context/static-1.2489",
      "video-embed": "/video-embed/static-1.26304",
      "web-interactives-components": "/web-interactives-components/static-1.9448",
      "web-interactives-data": "/web-interactives-data/static-1.9448",
      "wootric-nps": "/wootric-nps/static-1.4425",
      "xray-recommendation-lib": "/xray-recommendation-lib/static-1.4154",
      "codemirror": "/codemirror/static-5.61",
      "react-codemirror": "/react-codemirror/static-1.7944",
      "enviro": "/enviro/static-4.271",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2751",
      "raven-hubspot": "/raven-hubspot/static-1.3362",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "metrics-js": "/metrics-js/static-1.6916",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.1031",
      "moment": "/moment/static-3.26",
      "jquery": "/jquery/static-3.6",
      "chatspot-client-types": "/chatspot-client-types/static-1.6548",
      "chatspot-core": "/chatspot-core/static-1.11493",
      "chatspot-widget-iframe": "/chatspot-widget-iframe/static-1.11491",
      "framework-data-schema-resolvers": "/framework-data-schema-resolvers/static-1.3992",
      "frontend-preferences-client": "/frontend-preferences-client/static-1.5264",
      "graphql": "/graphql/static-1.50",
      "cms-field-types": "/cms-field-types/static-1.2202",
      "content-embed-lib": "/content-embed-lib/static-1.4310",
      "ExportDialog": "/ExportDialog/static-6.7628",
      "forms-utils-lib": "/forms-utils-lib/static-1.8088",
      "integrations-error-boundary-lib": "/integrations-error-boundary-lib/static-1.5354",
      "payments-embed-points-lib": "/payments-embed-points-lib/static-1.6259",
      "react-dnd-compat": "/react-dnd-compat/static-1.1850",
      "usage-tracker-container": "/usage-tracker-container/static-1.4964",
      "video-data-lib": "/video-data-lib/static-1.25461",
      "videoconference-integration-components": "/videoconference-integration-components/static-1.3259",
      "editor-iframe-lib": "/editor-iframe-lib/static-1.5585",
      "FireAlarmUi": "/FireAlarmUi/static-1.2677",
      "hubspot-apollo-client": "/hubspot-apollo-client/static-1.2154",
      "layout-dnd-components": "/layout-dnd-components/static-1.8916",
      "marketing-email-service-types": "/marketing-email-service-types/static-1.888",
      "quartz-core": "/quartz-core/static-1.3197",
      "rpc-client-utils": "/rpc-client-utils/static-1.2072",
      "smart-content-core": "/smart-content-core/static-1.33189",
      "social-composer-lib": "/social-composer-lib/static-1.50084",
      "structured-content-lib": "/structured-content-lib/static-1.15789",
      "timezone-utils": "/timezone-utils/static-2.2858",
      "ui-active-theme-content-creator-lib": "/ui-active-theme-content-creator-lib/static-1.27037",
      "ui-addon-video-player": "/ui-addon-video-player/static-1.6255",
      "ui-asset-management-lib": "/ui-asset-management-lib/static-1.7251",
      "ui-tool-access": "/ui-tool-access/static-1.7278",
      "content-icons-ui": "/content-icons-ui/static-1.1814",
      "FileManagerImages": "/FileManagerImages/static-1.27773",
      "hs-story-utils": "/hs-story-utils/static-1.6417",
      "bend-plugin-foundations-components": "/bend-plugin-foundations-components/static-1.383",
      "floating-ui": "/floating-ui/static-1.19",
      "framer-motion": "/framer-motion/static-1.29",
      "react-aria": "/react-aria/static-1.31",
      "tanstack-table": "/tanstack-table/static-1.10",
      "ui-fonts": "/ui-fonts/static-1.327",
      "HeadJS": "/HeadJS/static-2.517",
      "hs-test-utils-bend-plugin": "/hs-test-utils-bend-plugin/static-1.566",
      "hub-http-janus": "/hub-http-janus/static-1.585",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.550",
      "msw": "/msw/static-1.39",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "testing-library": "/testing-library/static-1.112",
      "ts-schema": "/ts-schema/static-1.1902",
      "i18n-data": "/i18n-data/static-1.187",
      "moment-timezone": "/moment-timezone/static-5.17",
      "NamespacedHubStyle": "/NamespacedHubStyle/static-2.7187",
      "react-dnd-ipeui-backend": "/react-dnd-ipeui-backend/static-1.456",
      "ui-coaching-tips": "/ui-coaching-tips/static-1.17471",
      "jasmine": "/jasmine/static-4.1733",
      "quartz-core-utils": "/quartz-core-utils/static-1.845",
      "flux-actions": "/flux-actions/static-1.250",
      "redux-observable": "/redux-observable/static-1.11",
      "ReduxMessenger": "/ReduxMessenger/static-2.8030",
      "rxjs": "/rxjs/static-5.10",
      "autolinker": "/autolinker/static-3.11",
      "sassPrefix": "/sassPrefix/static-1.121",
      "campaigns-build-lib": "/campaigns-build-lib/static-1.2068",
      "crm-pipelines-api-client-types": "/crm-pipelines-api-client-types/static-1.1801",
      "marketing-platform-lib": "/marketing-platform-lib/static-1.3971",
      "ai-rendering-library": "/ai-rendering-library/static-1.1754",
      "commerce-products-lib": "/commerce-products-lib/static-1.1510",
      "conditional-properties-service-client": "/conditional-properties-service-client/static-1.1970",
      "crm-message-bus": "/crm-message-bus/static-1.31352",
      "external-options-client-types": "/external-options-client-types/static-1.1907",
      "google-libphonenumber": "/google-libphonenumber/static-1.3",
      "inbound-db-properties-service-client": "/inbound-db-properties-service-client/static-1.1926",
      "inbounddb-objects-service-types": "/inbounddb-objects-service-types/static-1.1851",
      "pipeline-validation-client-types": "/pipeline-validation-client-types/static-1.1868",
      "react-immutable-render-mixin": "/react-immutable-render-mixin/static-1.9",
      "salesImages": "/salesImages/static-1.489",
      "foundations-theming-base": "/foundations-theming-base/static-1.311",
      "commerce-analytics-service-client": "/commerce-analytics-service-client/static-1.1455",
      "native-integrations-shared-strings": "/native-integrations-shared-strings/static-1.5193",
      "getting-started-shared-tasks": "/getting-started-shared-tasks/static-1.7239",
      "growth-data-modal": "/growth-data-modal/static-1.2383",
      "ui-shepherd-tracker": "/ui-shepherd-tracker/static-1.4698",
      "ui-universal-auth": "/ui-universal-auth/static-1.6008",
      "commerce-tours-lib": "/commerce-tours-lib/static-1.1328",
      "growth-onboarding-reliability": "/growth-onboarding-reliability/static-1.2497",
      "sales-checkout-service-client": "/sales-checkout-service-client/static-1.1467",
      "commerce-billing-lib": "/commerce-billing-lib/static-1.10975",
      "commerce-shared-components-lib": "/commerce-shared-components-lib/static-1.11510",
      "crm_components": "/crm_components/static-3.82377",
      "crm_data": "/crm_data/static-4.82377",
      "customer-data-filters-ui": "/customer-data-filters-ui/static-1.29653",
      "customer-data-tracking": "/customer-data-tracking/static-1.4248",
      "dispatcher": "/dispatcher/static-1.104",
      "framework-data-table": "/framework-data-table/static-2.5917",
      "property-description-translator": "/property-description-translator/static-1.1367",
      "csstype": "/csstype/static-1.8",
      "fast-json-stable-stringify": "/fast-json-stable-stringify/static-1.4",
      "redux-undo": "/redux-undo/static-1.10",
      "hubspotter-http": "/hubspotter-http/static-1.2495",
      "feature-store-service-types": "/feature-store-service-types/static-1.2312",
      "growth-monetization-service-types": "/growth-monetization-service-types/static-1.2319",
      "upgrade-management-service-types": "/upgrade-management-service-types/static-1.2303",
      "permission-components": "/permission-components/static-1.2730",
      "getting-started-shared-tasks-store": "/getting-started-shared-tasks-store/static-1.6357",
      "diff-match-patch": "/diff-match-patch/static-1.7",
      "browser-eslint": "/browser-eslint/static-2.9",
      "framework-builder-read-service-client": "/framework-builder-read-service-client/static-1.1888",
      "framework-data-schema-quick-fetch": "/framework-data-schema-quick-fetch/static-1.3896",
      "inbound-db-meta-service-client": "/inbound-db-meta-service-client/static-1.1587",
      "persist-promise": "/persist-promise/static-1.1223",
      "payments-enrollment-embed-lib": "/payments-enrollment-embed-lib/static-1.13737",
      "apollo-link-hub-http": "/apollo-link-hub-http/static-2.2160",
      "general-store": "/general-store/static-6.20",
      "hubspotter-http-shared-msw-handlers": "/hubspotter-http-shared-msw-handlers/static-1.5293",
      "mobile-manifest-mixins": "/mobile-manifest-mixins/static-1.288",
      "platform-navigation-bootstrap": "/platform-navigation-bootstrap/static-1.9214",
      "ui-addon-react-router-dom": "/ui-addon-react-router-dom/static-1.6995",
      "growth-onboarding-feedback-components": "/growth-onboarding-feedback-components/static-1.3848",
      "customer-data-filters": "/customer-data-filters/static-1.43466",
      "highlight.js": "/highlight.js/static-1.5",
      "markdown-it": "/markdown-it/static-1.6",
      "react-table": "/react-table/static-1.15",
      "reporting-action-components": "/reporting-action-components/static-1.32188",
      "reporting-ui-components": "/reporting-ui-components/static-2.54976",
      "reporting-visualizations": "/reporting-visualizations/static-1.49771",
      "ui-addon-teams": "/ui-addon-teams/static-1.17874",
      "commerce-tools-ui-lib": "/commerce-tools-ui-lib/static-1.2933",
      "usage-tracker-session-replay": "/usage-tracker-session-replay/static-1.2660",
      "conversations-skeleton-state": "/conversations-skeleton-state/static-1.4793",
      "crm_universal": "/crm_universal/static-1.18997",
      "customer-data-content": "/customer-data-content/static-1.22137",
      "reporting-data": "/reporting-data/static-1.57690",
      "crm_schema": "/crm_schema/static-3.82412",
      "customer-data-email": "/customer-data-email/static-1.13870",
      "customer-data-filters-ui-msw-helpers": "/customer-data-filters-ui-msw-helpers/static-1.12084",
      "FormUrlGenerator": "/FormUrlGenerator/static-2.2192",
      "lodash": "/lodash/static-4.7",
      "crm-links": "/crm-links/static-1.3365",
      "customer-data-associations": "/customer-data-associations/static-1.4675",
      "redux-toolkit": "/redux-toolkit/static-1.7",
      "ecosystem-marketplace-core": "/ecosystem-marketplace-core/static-1.14427",
      "stripe-embedded-components": "/stripe-embedded-components/static-1.3256",
      "platform-infra-nav-components": "/platform-infra-nav-components/static-1.5594",
      "deep-diff": "/deep-diff/static-1.5",
      "dashboard-lib": "/dashboard-lib/static-1.71289",
      "reporting-snowflake": "/reporting-snowflake/static-1.46317",
      "share-with-third-party-component-lib": "/share-with-third-party-component-lib/static-1.5869",
      "highcharts": "/highcharts/static-8.69",
      "reporting-plugins": "/reporting-plugins/static-1.4567",
      "reporting-reports": "/reporting-reports/static-1.57380",
      "customer-data-rte": "/customer-data-rte/static-1.25687",
      "cv-backend-services": "/cv-backend-services/static-1.851",
      "hubspot-prosemirror": "/hubspot-prosemirror/static-1.10148",
      "prosemirror": "/prosemirror/static-1.13",
      "qrcode-generator": "/qrcode-generator/static-1.36",
      "amplitude-session-replay-browser": "/amplitude-session-replay-browser/static-1.6",
      "SalesContentIndexUI": "/SalesContentIndexUI/static-3.4909",
      "sequences-lib": "/sequences-lib/static-1.3818",
      "reporting-client-types": "/reporting-client-types/static-1.1152",
      "location-customization-utils-lib": "/location-customization-utils-lib/static-1.342",
      "immutable-less": "/immutable-less/static-1.2053",
      "marketplace-ui-apps-core": "/marketplace-ui-apps-core/static-1.9012",
      "marketplace-ui-assets-core": "/marketplace-ui-assets-core/static-1.10418",
      "marketplace-ui-common": "/marketplace-ui-common/static-1.10474",
      "marketplace-ui-reviews": "/marketplace-ui-reviews/static-1.10436",
      "marketplace-ui-solutions-core": "/marketplace-ui-solutions-core/static-1.10449",
      "signup-constants": "/signup-constants/static-1.13873",
      "signup-ui-url-generator": "/signup-ui-url-generator/static-2.5329",
      "stripe-connect-js": "/stripe-connect-js/static-1.30",
      "onboarding-scopes": "/onboarding-scopes/static-1.3819",
      "redux-actions": "/redux-actions/static-3.7",
      "reporting-datasets-permissions-lib": "/reporting-datasets-permissions-lib/static-1.3113",
      "draft-content-plugins": "/draft-content-plugins/static-1.20972",
      "draft-convert": "/draft-convert/static-2.31",
      "draft-extend": "/draft-extend/static-2.46",
      "draft-js": "/draft-js/static-5.37",
      "draft-plugins": "/draft-plugins/static-2.17257",
      "knowledge-content-types": "/knowledge-content-types/static-1.6833",
      "sequences-client-types-lib": "/sequences-client-types-lib/static-1.3674",
      "crm-record-cards-service-types": "/crm-record-cards-service-types/static-1.1665",
      "developer-experience-shared-components": "/developer-experience-shared-components/static-1.7211",
      "marketplace-ui-client-types": "/marketplace-ui-client-types/static-1.7878",
      "ui-addon-integrations-directory-panel": "/ui-addon-integrations-directory-panel/static-2.5337",
      "ui-extensibility-client-types": "/ui-extensibility-client-types/static-1.1901",
      "react-window": "/react-window/static-1.13",
      "trellis-ecosystem": "/trellis-ecosystem/static-1.2667",
      "apps-service-types": "/apps-service-types/static-1.2093",
      "oauth-service-types": "/oauth-service-types/static-1.1884",
      "trellis-story-utils": "/trellis-story-utils/static-1.1914"
    },
    "project": "page-editor-ui",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};