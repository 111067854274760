var __WEBPACK_NAMESPACE_OBJECT__ = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "page-editor-ui": "static-1.26953",
      "apollo-dlb": "static-3.41",
      "backbone": "static-1.6",
      "codemirror-dlb": "static-1.48381",
      "collaboration-sidebar": "static-1.47252",
      "ContentComponents": "static-2.62013",
      "ContentData": "static-1.59131",
      "ContentEditorUI": "static-2.66831",
      "ContentUtils": "static-1.59053",
      "data-fetching-client": "static-1.6640",
      "editor-components": "static-1.9914",
      "emoji-mart": "static-3.4",
      "FileManagerLib": "static-1.30104",
      "foundations-assets": "static-1.2195",
      "foundations-components": "static-1.4075",
      "head-dlb": "static-1.2378",
      "hs-test-utils": "static-1.5432",
      "hub-http": "static-1.2732",
      "hub-http-shared-msw-handlers": "static-1.5257",
      "hubspot-dlb": "static-2.439",
      "HubStyleTokens": "static-2.8985",
      "I18n": "static-7.1298",
      "InpageEditorUI": "static-1.75279",
      "jasmine-immutable": "static-1.353",
      "jasmine-runner": "static-1.3798",
      "layout-dnd-utils": "static-1.7861",
      "marketing-discovery-utils": "static-1.724",
      "quartz": "static-1.3938",
      "react-dlb": "static-1.41",
      "react-dnd": "static-4.38",
      "react-dnd-html5-backend": "static-4.5",
      "react-redux": "static-7.16",
      "react-router-dom": "static-5.26",
      "react-utils": "static-2.3122",
      "redux-thunk": "static-2.17",
      "settings-ui-lib": "static-1.9780",
      "sinon": "static-1.9",
      "tinymce": "static-6.5",
      "ui-addon-draggable": "static-1.4353",
      "ui-components-test-utils": "static-1.3126",
      "ui-images": "static-2.763",
      "ui-memberships-settings-lib": "static-1.22916",
      "webpack-env": "static-1.41",
      "ai-components-ui-library": "static-1.3372",
      "apollo-stack-hubspot": "static-3.43",
      "atom": "static-1.2822",
      "blueimp-md5": "static-1.8",
      "campaigns-lib": "static-1.18831",
      "classnames": "static-2.10",
      "content-assistance-lib": "static-1.4454",
      "content-management-data-lib": "static-1.2412",
      "copilot-plugins": "static-1.2294",
      "copilot-toolkit": "static-1.2320",
      "cropperjs": "static-1.10",
      "cssUtils": "static-1.319",
      "cta-picker": "static-1.27855",
      "customer-data-objects": "static-1.4950",
      "customer-data-objects-ui-components": "static-1.10094",
      "customer-data-properties": "static-1.40913",
      "customer-data-property-utils": "static-1.5139",
      "customer-data-reference-ui-components": "static-1.10185",
      "customer-data-ui-utilities": "static-1.7144",
      "data-token-picker": "static-1.11894",
      "data-token-views": "static-1.11878",
      "error-messaging-js": "static-1.2752",
      "feedback-loader": "static-1.24087",
      "file-manager-components": "static-1.14461",
      "FileManagerCore": "static-1.29961",
      "FormsCommonComponents": "static-1.76947",
      "foundations-theming": "static-1.1297",
      "fuzzy": "static-1.8",
      "growth-onboarding-confetti": "static-1.562",
      "growth-payments-core": "static-1.17432",
      "history": "static-4.15",
      "hs-lodash": "static-4.9",
      "hs-promise-utils": "static-1.2857",
      "hub-http-contrib": "static-1.626",
      "hubspot-url-utils": "static-1.1893",
      "HubStyle": "static-2.9295",
      "icons": "static-2.585",
      "immer": "static-1.33",
      "immutable": "static-2.19",
      "InboxConnectUtils": "static-4.8096",
      "integrations-lib": "static-1.7875",
      "interframe": "static-3.1971",
      "laboratory-lib": "static-3.4479",
      "layout-data-lib": "static-1.6186",
      "media-bridge-lib": "static-1.14502",
      "nav-meta": "static-1.23220",
      "object-builder-ui-client": "static-1.35903",
      "onboarding-tours": "static-1.11662",
      "onboarding-tours-client": "static-1.1945",
      "outpost": "static-1.1765",
      "PatternValidationJS": "static-1.437",
      "payment-link-components": "static-1.13993",
      "payments-post-enroll-local-storage-lib": "static-1.2435",
      "pictos": "static-7.150",
      "PortalIdParser": "static-2.250",
      "products-ui-components": "static-1.31845",
      "property-translator": "static-1.2890",
      "quick-fetch": "static-1.1826",
      "raven": "static-3.3055",
      "react": "static-7.133",
      "react-beautiful-dnd": "static-1.13",
      "react-colorpicker": "static-2.7577",
      "react-dom": "static-7.85",
      "react-flip-move": "static-1.39",
      "react-immutable-proptypes": "static-2.8",
      "react-input-autosize": "static-2.17",
      "react-rhumb": "static-1.13890",
      "react-select-plus": "static-1.65",
      "react-tinymce": "static-1.46477",
      "react-transition-group": "static-1.7",
      "react-virtualized": "static-2.66",
      "redux": "static-4.16",
      "redux-request-state": "static-1.7793",
      "reference-resolvers": "static-1.5680",
      "reference-resolvers-lite": "static-1.5244",
      "reselect": "static-2.16",
      "rich-text-lib": "static-1.15366",
      "sanitize": "static-1.6",
      "sanitize-text": "static-1.5595",
      "seamless-immutable": "static-7.6",
      "self-service-api": "static-1.7494",
      "SharedI18nStrings": "static-1.160",
      "SharedLegalStrings": "static-1.1227",
      "simple-menus-core": "static-1.11602",
      "simple-menus-lib": "static-1.13120",
      "smart-content-lib": "static-1.33670",
      "styled-components": "static-2.40",
      "StyleGuideUI": "static-3.431",
      "superstore": "static-1.2146",
      "theme-previewer-lib": "static-1.42576",
      "tinymce-config": "static-2.45604",
      "tinymce-copilot": "static-1.7672",
      "tinymce-data": "static-1.31686",
      "tinymce-plugins": "static-1.48717",
      "tinymce-themes": "static-1.47571",
      "transmute": "static-2.29",
      "ui-addon-avatars": "static-2.6937",
      "ui-addon-emoji-picker": "static-1.7276",
      "ui-addon-form-validation": "static-1.8377",
      "ui-addon-i18n": "static-1.8180",
      "ui-addon-iframeable": "static-1.6459",
      "ui-addon-opt": "static-4.6128",
      "ui-addon-sales-email": "static-1.2637",
      "ui-addon-upgrades": "static-9.14130",
      "ui-business-units-lib": "static-1.6014",
      "ui-gdpr-components": "static-1.7569",
      "ui-product-approvals": "static-1.20751",
      "ui-shepherd-react": "static-3.7286",
      "ui-suspension-banners-lib": "static-1.3800",
      "UIComponents": "static-3.5935",
      "underscore": "static-1.8",
      "urlinator": "static-1.2026",
      "usage-tracker": "static-1.4947",
      "usage-tracker-core": "static-1.4797",
      "user-context": "static-1.2455",
      "video-embed": "static-1.26120",
      "web-interactives-components": "static-1.9382",
      "web-interactives-data": "static-1.9382",
      "wootric-nps": "static-1.4406",
      "xray-recommendation-lib": "static-1.4109",
      "codemirror": "static-5.61",
      "react-codemirror": "static-1.7926",
      "enviro": "static-4.271",
      "hs-promise-rejection-tracking": "static-1.2751",
      "raven-hubspot": "static-1.3362",
      "hoist-non-react-statics": "static-3.9",
      "metrics-js": "static-1.6916",
      "bend-plugin-trellis-migration": "static-1.1008",
      "moment": "static-3.26",
      "jquery": "static-3.6",
      "chatspot-client-types": "static-1.6482",
      "chatspot-core": "static-1.11298",
      "chatspot-widget-iframe": "static-1.11296",
      "framework-data-schema-resolvers": "static-1.3958",
      "frontend-preferences-client": "static-1.5232",
      "graphql": "static-1.50",
      "cms-field-types": "static-1.2183",
      "content-embed-lib": "static-1.4276",
      "ExportDialog": "static-6.7582",
      "forms-utils-lib": "static-1.8032",
      "integrations-error-boundary-lib": "static-1.5288",
      "payments-embed-points-lib": "static-1.6148",
      "react-dnd-compat": "static-1.1815",
      "usage-tracker-container": "static-1.4937",
      "video-data-lib": "static-1.25277",
      "videoconference-integration-components": "static-1.3230",
      "editor-iframe-lib": "static-1.5557",
      "FireAlarmUi": "static-1.2658",
      "hubspot-apollo-client": "static-1.2130",
      "layout-dnd-components": "static-1.8886",
      "marketing-email-service-types": "static-1.879",
      "quartz-core": "static-1.3157",
      "rpc-client-utils": "static-1.2045",
      "smart-content-core": "static-1.33015",
      "social-composer-lib": "static-1.49910",
      "structured-content-lib": "static-1.15577",
      "timezone-utils": "static-2.2840",
      "ui-active-theme-content-creator-lib": "static-1.26865",
      "ui-addon-video-player": "static-1.6237",
      "ui-asset-management-lib": "static-1.7198",
      "ui-tool-access": "static-1.7249",
      "content-icons-ui": "static-1.1812",
      "FileManagerImages": "static-1.27612",
      "hs-story-utils": "static-1.6378",
      "bend-plugin-foundations-components": "static-1.343",
      "floating-ui": "static-1.19",
      "framer-motion": "static-1.29",
      "react-aria": "static-1.31",
      "tanstack-table": "static-1.10",
      "ui-fonts": "static-1.327",
      "HeadJS": "static-2.515",
      "hs-test-utils-bend-plugin": "static-1.543",
      "hub-http-janus": "static-1.583",
      "hub-http-rxjs": "static-1.547",
      "msw": "static-1.39",
      "redux-mock-store": "static-1.8",
      "testing-library": "static-1.112",
      "ts-schema": "static-1.1884",
      "i18n-data": "static-1.187",
      "moment-timezone": "static-5.17",
      "NamespacedHubStyle": "static-2.7170",
      "react-dnd-ipeui-backend": "static-1.454",
      "ui-coaching-tips": "static-1.17254",
      "jasmine": "static-4.1710",
      "browserslist-config-hubspot": "static-1.144",
      "quartz-core-utils": "static-1.805",
      "flux-actions": "static-1.249",
      "redux-observable": "static-1.11",
      "ReduxMessenger": "static-2.8012",
      "rxjs": "static-5.10",
      "autolinker": "static-3.11",
      "sassPrefix": "static-1.120",
      "campaigns-build-lib": "static-1.1812",
      "crm-pipelines-api-client-types": "static-1.1783",
      "marketing-platform-lib": "static-1.3942",
      "ai-rendering-library": "static-1.1655",
      "conditional-properties-service-client": "static-1.1952",
      "crm-message-bus": "static-1.31103",
      "external-options-client-types": "static-1.1889",
      "google-libphonenumber": "static-1.3",
      "inbound-db-properties-service-client": "static-1.1908",
      "inbounddb-objects-service-types": "static-1.1832",
      "pipeline-validation-client-types": "static-1.1850",
      "react-immutable-render-mixin": "static-1.9",
      "salesImages": "static-1.489",
      "foundations-theming-base": "static-1.282",
      "commerce-analytics-service-client": "static-1.1430",
      "native-integrations-shared-strings": "static-1.5175",
      "getting-started-shared-tasks": "static-1.7108",
      "growth-data-modal": "static-1.2353",
      "ui-shepherd-tracker": "static-1.4667",
      "ui-universal-auth": "static-1.5967",
      "commerce-tours-lib": "static-1.1257",
      "growth-onboarding-reliability": "static-1.2479",
      "sales-checkout-service-client": "static-1.1442",
      "commerce-billing-lib": "static-1.10755",
      "commerce-products-lib": "static-1.1321",
      "commerce-shared-components-lib": "static-1.11399",
      "crm_components": "static-3.82098",
      "crm_data": "static-4.82098",
      "customer-data-filters-ui": "static-1.29438",
      "customer-data-tracking": "static-1.4222",
      "dispatcher": "static-1.104",
      "framework-data-table": "static-2.5831",
      "property-description-translator": "static-1.1362",
      "csstype": "static-1.8",
      "fast-json-stable-stringify": "static-1.4",
      "redux-undo": "static-1.10",
      "hubspotter-http": "static-1.2475",
      "feature-store-service-types": "static-1.2286",
      "growth-monetization-service-types": "static-1.2293",
      "upgrade-management-service-types": "static-1.2277",
      "permission-components": "static-1.2711",
      "getting-started-shared-tasks-store": "static-1.6226",
      "diff-match-patch": "static-1.7",
      "browser-eslint": "static-2.9",
      "framework-builder-read-service-client": "static-1.1870",
      "framework-data-schema-quick-fetch": "static-1.3862",
      "inbound-db-meta-service-client": "static-1.1569",
      "persist-promise": "static-1.1204",
      "payments-enrollment-embed-lib": "static-1.13565",
      "apollo-link-hub-http": "static-2.2135",
      "general-store": "static-6.20",
      "hubspotter-http-shared-msw-handlers": "static-1.5255",
      "mobile-manifest-mixins": "static-1.286",
      "platform-navigation-bootstrap": "static-1.9178",
      "ui-addon-react-router-dom": "static-1.6978",
      "growth-onboarding-feedback-components": "static-1.3829",
      "customer-data-filters": "static-1.43251",
      "highlight.js": "static-1.5",
      "markdown-it": "static-1.6",
      "react-table": "static-1.15",
      "reporting-action-components": "static-1.32068",
      "reporting-ui-components": "static-2.54843",
      "reporting-visualizations": "static-1.49638",
      "ui-addon-teams": "static-1.17830",
      "commerce-tools-ui-lib": "static-1.2777",
      "subscriptions-iframe-lib": "static-1.17533",
      "usage-tracker-session-replay": "static-1.2633",
      "conversations-skeleton-state": "static-1.4774",
      "crm_universal": "static-1.18976",
      "customer-data-content": "static-1.22059",
      "reporting-data": "static-1.57557",
      "crm_schema": "static-3.82133",
      "customer-data-email": "static-1.13812",
      "customer-data-filters-ui-msw-helpers": "static-1.11869",
      "FormUrlGenerator": "static-2.2190",
      "lodash": "static-4.7",
      "crm-links": "static-1.3332",
      "customer-data-associations": "static-1.4648",
      "redux-toolkit": "static-1.7",
      "ecosystem-marketplace-core": "static-1.14351",
      "stripe-embedded-components": "static-1.3193",
      "platform-infra-nav-components": "static-1.5576",
      "deep-diff": "static-1.5",
      "dashboard-lib": "static-1.71080",
      "reporting-snowflake": "static-1.46184",
      "share-with-third-party-component-lib": "static-1.5843",
      "highcharts": "static-8.69",
      "reporting-plugins": "static-1.4525",
      "reporting-reports": "static-1.57247",
      "customer-data-rte": "static-1.25573",
      "cv-backend-services": "static-1.848",
      "hubspot-prosemirror": "static-1.9941",
      "prosemirror": "static-1.13",
      "qrcode-generator": "static-1.36",
      "invoices-ui-lib": "static-1.45285",
      "amplitude-session-replay-browser": "static-1.6",
      "SalesContentIndexUI": "static-3.4860",
      "sequences-lib": "static-1.3757",
      "reporting-client-types": "static-1.1125",
      "immutable-less": "static-1.2034",
      "marketplace-ui-apps-core": "static-1.8913",
      "marketplace-ui-assets-core": "static-1.10319",
      "marketplace-ui-common": "static-1.10375",
      "marketplace-ui-reviews": "static-1.10337",
      "marketplace-ui-solutions-core": "static-1.10350",
      "signup-constants": "static-1.13792",
      "signup-ui-url-generator": "static-2.5310",
      "stripe-connect-js": "static-1.30",
      "onboarding-scopes": "static-1.3801",
      "redux-actions": "static-3.7",
      "reporting-datasets-permissions-lib": "static-1.3021",
      "draft-content-plugins": "static-1.20853",
      "draft-convert": "static-2.31",
      "draft-extend": "static-2.46",
      "draft-js": "static-5.37",
      "draft-plugins": "static-2.17200",
      "knowledge-content-types": "static-1.6815",
      "sequences-client-types-lib": "static-1.3613",
      "developer-experience-shared-components": "static-1.7179",
      "marketplace-ui-client-types": "static-1.7779",
      "ui-addon-integrations-directory-panel": "static-2.5310",
      "ui-extensibility-client-types": "static-1.1883",
      "react-window": "static-1.13",
      "trellis-ecosystem": "static-1.2616",
      "apps-service-types": "static-1.2066",
      "oauth-service-types": "static-1.1857",
      "trellis-story-utils": "static-1.1894"
    },
    "depPathPrefixes": {
      "page-editor-ui": "/page-editor-ui/static-1.26953",
      "apollo-dlb": "/apollo-dlb/static-3.41",
      "backbone": "/backbone/static-1.6",
      "codemirror-dlb": "/codemirror-dlb/static-1.48381",
      "collaboration-sidebar": "/collaboration-sidebar/static-1.47252",
      "ContentComponents": "/ContentComponents/static-2.62013",
      "ContentData": "/ContentData/static-1.59131",
      "ContentEditorUI": "/ContentEditorUI/static-2.66831",
      "ContentUtils": "/ContentUtils/static-1.59053",
      "data-fetching-client": "/data-fetching-client/static-1.6640",
      "editor-components": "/editor-components/static-1.9914",
      "emoji-mart": "/emoji-mart/static-3.4",
      "FileManagerLib": "/FileManagerLib/static-1.30104",
      "foundations-assets": "/foundations-assets/static-1.2195",
      "foundations-components": "/foundations-components/static-1.4075",
      "head-dlb": "/head-dlb/static-1.2378",
      "hs-test-utils": "/hs-test-utils/static-1.5432",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-shared-msw-handlers": "/hub-http-shared-msw-handlers/static-1.5257",
      "hubspot-dlb": "/hubspot-dlb/static-2.439",
      "HubStyleTokens": "/HubStyleTokens/static-2.8985",
      "I18n": "/I18n/static-7.1298",
      "InpageEditorUI": "/InpageEditorUI/static-1.75279",
      "jasmine-immutable": "/jasmine-immutable/static-1.353",
      "jasmine-runner": "/jasmine-runner/static-1.3798",
      "layout-dnd-utils": "/layout-dnd-utils/static-1.7861",
      "marketing-discovery-utils": "/marketing-discovery-utils/static-1.724",
      "quartz": "/quartz/static-1.3938",
      "react-dlb": "/react-dlb/static-1.41",
      "react-dnd": "/react-dnd/static-4.38",
      "react-dnd-html5-backend": "/react-dnd-html5-backend/static-4.5",
      "react-redux": "/react-redux/static-7.16",
      "react-router-dom": "/react-router-dom/static-5.26",
      "react-utils": "/react-utils/static-2.3122",
      "redux-thunk": "/redux-thunk/static-2.17",
      "settings-ui-lib": "/settings-ui-lib/static-1.9780",
      "sinon": "/sinon/static-1.9",
      "tinymce": "/tinymce/static-6.5",
      "ui-addon-draggable": "/ui-addon-draggable/static-1.4353",
      "ui-components-test-utils": "/ui-components-test-utils/static-1.3126",
      "ui-images": "/ui-images/static-2.763",
      "ui-memberships-settings-lib": "/ui-memberships-settings-lib/static-1.22916",
      "webpack-env": "/webpack-env/static-1.41",
      "ai-components-ui-library": "/ai-components-ui-library/static-1.3372",
      "apollo-stack-hubspot": "/apollo-stack-hubspot/static-3.43",
      "atom": "/atom/static-1.2822",
      "blueimp-md5": "/blueimp-md5/static-1.8",
      "campaigns-lib": "/campaigns-lib/static-1.18831",
      "classnames": "/classnames/static-2.10",
      "content-assistance-lib": "/content-assistance-lib/static-1.4454",
      "content-management-data-lib": "/content-management-data-lib/static-1.2412",
      "copilot-plugins": "/copilot-plugins/static-1.2294",
      "copilot-toolkit": "/copilot-toolkit/static-1.2320",
      "cropperjs": "/cropperjs/static-1.10",
      "cssUtils": "/cssUtils/static-1.319",
      "cta-picker": "/cta-picker/static-1.27855",
      "customer-data-objects": "/customer-data-objects/static-1.4950",
      "customer-data-objects-ui-components": "/customer-data-objects-ui-components/static-1.10094",
      "customer-data-properties": "/customer-data-properties/static-1.40913",
      "customer-data-property-utils": "/customer-data-property-utils/static-1.5139",
      "customer-data-reference-ui-components": "/customer-data-reference-ui-components/static-1.10185",
      "customer-data-ui-utilities": "/customer-data-ui-utilities/static-1.7144",
      "data-token-picker": "/data-token-picker/static-1.11894",
      "data-token-views": "/data-token-views/static-1.11878",
      "error-messaging-js": "/error-messaging-js/static-1.2752",
      "feedback-loader": "/feedback-loader/static-1.24087",
      "file-manager-components": "/file-manager-components/static-1.14461",
      "FileManagerCore": "/FileManagerCore/static-1.29961",
      "FormsCommonComponents": "/FormsCommonComponents/static-1.76947",
      "foundations-theming": "/foundations-theming/static-1.1297",
      "fuzzy": "/fuzzy/static-1.8",
      "growth-onboarding-confetti": "/growth-onboarding-confetti/static-1.562",
      "growth-payments-core": "/growth-payments-core/static-1.17432",
      "history": "/history/static-4.15",
      "hs-lodash": "/hs-lodash/static-4.9",
      "hs-promise-utils": "/hs-promise-utils/static-1.2857",
      "hub-http-contrib": "/hub-http-contrib/static-1.626",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1893",
      "HubStyle": "/HubStyle/static-2.9295",
      "icons": "/icons/static-2.585",
      "immer": "/immer/static-1.33",
      "immutable": "/immutable/static-2.19",
      "InboxConnectUtils": "/InboxConnectUtils/static-4.8096",
      "integrations-lib": "/integrations-lib/static-1.7875",
      "interframe": "/interframe/static-3.1971",
      "laboratory-lib": "/laboratory-lib/static-3.4479",
      "layout-data-lib": "/layout-data-lib/static-1.6186",
      "media-bridge-lib": "/media-bridge-lib/static-1.14502",
      "nav-meta": "/nav-meta/static-1.23220",
      "object-builder-ui-client": "/object-builder-ui-client/static-1.35903",
      "onboarding-tours": "/onboarding-tours/static-1.11662",
      "onboarding-tours-client": "/onboarding-tours-client/static-1.1945",
      "outpost": "/outpost/static-1.1765",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "payment-link-components": "/payment-link-components/static-1.13993",
      "payments-post-enroll-local-storage-lib": "/payments-post-enroll-local-storage-lib/static-1.2435",
      "pictos": "/pictos/static-7.150",
      "PortalIdParser": "/PortalIdParser/static-2.250",
      "products-ui-components": "/products-ui-components/static-1.31845",
      "property-translator": "/property-translator/static-1.2890",
      "quick-fetch": "/quick-fetch/static-1.1826",
      "raven": "/raven/static-3.3055",
      "react": "/react/static-7.133",
      "react-beautiful-dnd": "/react-beautiful-dnd/static-1.13",
      "react-colorpicker": "/react-colorpicker/static-2.7577",
      "react-dom": "/react-dom/static-7.85",
      "react-flip-move": "/react-flip-move/static-1.39",
      "react-immutable-proptypes": "/react-immutable-proptypes/static-2.8",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "react-rhumb": "/react-rhumb/static-1.13890",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-tinymce": "/react-tinymce/static-1.46477",
      "react-transition-group": "/react-transition-group/static-1.7",
      "react-virtualized": "/react-virtualized/static-2.66",
      "redux": "/redux/static-4.16",
      "redux-request-state": "/redux-request-state/static-1.7793",
      "reference-resolvers": "/reference-resolvers/static-1.5680",
      "reference-resolvers-lite": "/reference-resolvers-lite/static-1.5244",
      "reselect": "/reselect/static-2.16",
      "rich-text-lib": "/rich-text-lib/static-1.15366",
      "sanitize": "/sanitize/static-1.6",
      "sanitize-text": "/sanitize-text/static-1.5595",
      "seamless-immutable": "/seamless-immutable/static-7.6",
      "self-service-api": "/self-service-api/static-1.7494",
      "SharedI18nStrings": "/SharedI18nStrings/static-1.160",
      "SharedLegalStrings": "/SharedLegalStrings/static-1.1227",
      "simple-menus-core": "/simple-menus-core/static-1.11602",
      "simple-menus-lib": "/simple-menus-lib/static-1.13120",
      "smart-content-lib": "/smart-content-lib/static-1.33670",
      "styled-components": "/styled-components/static-2.40",
      "StyleGuideUI": "/StyleGuideUI/static-3.431",
      "superstore": "/superstore/static-1.2146",
      "theme-previewer-lib": "/theme-previewer-lib/static-1.42576",
      "tinymce-config": "/tinymce-config/static-2.45604",
      "tinymce-copilot": "/tinymce-copilot/static-1.7672",
      "tinymce-data": "/tinymce-data/static-1.31686",
      "tinymce-plugins": "/tinymce-plugins/static-1.48717",
      "tinymce-themes": "/tinymce-themes/static-1.47571",
      "transmute": "/transmute/static-2.29",
      "ui-addon-avatars": "/ui-addon-avatars/static-2.6937",
      "ui-addon-emoji-picker": "/ui-addon-emoji-picker/static-1.7276",
      "ui-addon-form-validation": "/ui-addon-form-validation/static-1.8377",
      "ui-addon-i18n": "/ui-addon-i18n/static-1.8180",
      "ui-addon-iframeable": "/ui-addon-iframeable/static-1.6459",
      "ui-addon-opt": "/ui-addon-opt/static-4.6128",
      "ui-addon-sales-email": "/ui-addon-sales-email/static-1.2637",
      "ui-addon-upgrades": "/ui-addon-upgrades/static-9.14130",
      "ui-business-units-lib": "/ui-business-units-lib/static-1.6014",
      "ui-gdpr-components": "/ui-gdpr-components/static-1.7569",
      "ui-product-approvals": "/ui-product-approvals/static-1.20751",
      "ui-shepherd-react": "/ui-shepherd-react/static-3.7286",
      "ui-suspension-banners-lib": "/ui-suspension-banners-lib/static-1.3800",
      "UIComponents": "/UIComponents/static-3.5935",
      "underscore": "/underscore/static-1.8",
      "urlinator": "/urlinator/static-1.2026",
      "usage-tracker": "/usage-tracker/static-1.4947",
      "usage-tracker-core": "/usage-tracker-core/static-1.4797",
      "user-context": "/user-context/static-1.2455",
      "video-embed": "/video-embed/static-1.26120",
      "web-interactives-components": "/web-interactives-components/static-1.9382",
      "web-interactives-data": "/web-interactives-data/static-1.9382",
      "wootric-nps": "/wootric-nps/static-1.4406",
      "xray-recommendation-lib": "/xray-recommendation-lib/static-1.4109",
      "codemirror": "/codemirror/static-5.61",
      "react-codemirror": "/react-codemirror/static-1.7926",
      "enviro": "/enviro/static-4.271",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2751",
      "raven-hubspot": "/raven-hubspot/static-1.3362",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "metrics-js": "/metrics-js/static-1.6916",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.1008",
      "moment": "/moment/static-3.26",
      "jquery": "/jquery/static-3.6",
      "chatspot-client-types": "/chatspot-client-types/static-1.6482",
      "chatspot-core": "/chatspot-core/static-1.11298",
      "chatspot-widget-iframe": "/chatspot-widget-iframe/static-1.11296",
      "framework-data-schema-resolvers": "/framework-data-schema-resolvers/static-1.3958",
      "frontend-preferences-client": "/frontend-preferences-client/static-1.5232",
      "graphql": "/graphql/static-1.50",
      "cms-field-types": "/cms-field-types/static-1.2183",
      "content-embed-lib": "/content-embed-lib/static-1.4276",
      "ExportDialog": "/ExportDialog/static-6.7582",
      "forms-utils-lib": "/forms-utils-lib/static-1.8032",
      "integrations-error-boundary-lib": "/integrations-error-boundary-lib/static-1.5288",
      "payments-embed-points-lib": "/payments-embed-points-lib/static-1.6148",
      "react-dnd-compat": "/react-dnd-compat/static-1.1815",
      "usage-tracker-container": "/usage-tracker-container/static-1.4937",
      "video-data-lib": "/video-data-lib/static-1.25277",
      "videoconference-integration-components": "/videoconference-integration-components/static-1.3230",
      "editor-iframe-lib": "/editor-iframe-lib/static-1.5557",
      "FireAlarmUi": "/FireAlarmUi/static-1.2658",
      "hubspot-apollo-client": "/hubspot-apollo-client/static-1.2130",
      "layout-dnd-components": "/layout-dnd-components/static-1.8886",
      "marketing-email-service-types": "/marketing-email-service-types/static-1.879",
      "quartz-core": "/quartz-core/static-1.3157",
      "rpc-client-utils": "/rpc-client-utils/static-1.2045",
      "smart-content-core": "/smart-content-core/static-1.33015",
      "social-composer-lib": "/social-composer-lib/static-1.49910",
      "structured-content-lib": "/structured-content-lib/static-1.15577",
      "timezone-utils": "/timezone-utils/static-2.2840",
      "ui-active-theme-content-creator-lib": "/ui-active-theme-content-creator-lib/static-1.26865",
      "ui-addon-video-player": "/ui-addon-video-player/static-1.6237",
      "ui-asset-management-lib": "/ui-asset-management-lib/static-1.7198",
      "ui-tool-access": "/ui-tool-access/static-1.7249",
      "content-icons-ui": "/content-icons-ui/static-1.1812",
      "FileManagerImages": "/FileManagerImages/static-1.27612",
      "hs-story-utils": "/hs-story-utils/static-1.6378",
      "bend-plugin-foundations-components": "/bend-plugin-foundations-components/static-1.343",
      "floating-ui": "/floating-ui/static-1.19",
      "framer-motion": "/framer-motion/static-1.29",
      "react-aria": "/react-aria/static-1.31",
      "tanstack-table": "/tanstack-table/static-1.10",
      "ui-fonts": "/ui-fonts/static-1.327",
      "HeadJS": "/HeadJS/static-2.515",
      "hs-test-utils-bend-plugin": "/hs-test-utils-bend-plugin/static-1.543",
      "hub-http-janus": "/hub-http-janus/static-1.583",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.547",
      "msw": "/msw/static-1.39",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "testing-library": "/testing-library/static-1.112",
      "ts-schema": "/ts-schema/static-1.1884",
      "i18n-data": "/i18n-data/static-1.187",
      "moment-timezone": "/moment-timezone/static-5.17",
      "NamespacedHubStyle": "/NamespacedHubStyle/static-2.7170",
      "react-dnd-ipeui-backend": "/react-dnd-ipeui-backend/static-1.454",
      "ui-coaching-tips": "/ui-coaching-tips/static-1.17254",
      "jasmine": "/jasmine/static-4.1710",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.144",
      "quartz-core-utils": "/quartz-core-utils/static-1.805",
      "flux-actions": "/flux-actions/static-1.249",
      "redux-observable": "/redux-observable/static-1.11",
      "ReduxMessenger": "/ReduxMessenger/static-2.8012",
      "rxjs": "/rxjs/static-5.10",
      "autolinker": "/autolinker/static-3.11",
      "sassPrefix": "/sassPrefix/static-1.120",
      "campaigns-build-lib": "/campaigns-build-lib/static-1.1812",
      "crm-pipelines-api-client-types": "/crm-pipelines-api-client-types/static-1.1783",
      "marketing-platform-lib": "/marketing-platform-lib/static-1.3942",
      "ai-rendering-library": "/ai-rendering-library/static-1.1655",
      "conditional-properties-service-client": "/conditional-properties-service-client/static-1.1952",
      "crm-message-bus": "/crm-message-bus/static-1.31103",
      "external-options-client-types": "/external-options-client-types/static-1.1889",
      "google-libphonenumber": "/google-libphonenumber/static-1.3",
      "inbound-db-properties-service-client": "/inbound-db-properties-service-client/static-1.1908",
      "inbounddb-objects-service-types": "/inbounddb-objects-service-types/static-1.1832",
      "pipeline-validation-client-types": "/pipeline-validation-client-types/static-1.1850",
      "react-immutable-render-mixin": "/react-immutable-render-mixin/static-1.9",
      "salesImages": "/salesImages/static-1.489",
      "foundations-theming-base": "/foundations-theming-base/static-1.282",
      "commerce-analytics-service-client": "/commerce-analytics-service-client/static-1.1430",
      "native-integrations-shared-strings": "/native-integrations-shared-strings/static-1.5175",
      "getting-started-shared-tasks": "/getting-started-shared-tasks/static-1.7108",
      "growth-data-modal": "/growth-data-modal/static-1.2353",
      "ui-shepherd-tracker": "/ui-shepherd-tracker/static-1.4667",
      "ui-universal-auth": "/ui-universal-auth/static-1.5967",
      "commerce-tours-lib": "/commerce-tours-lib/static-1.1257",
      "growth-onboarding-reliability": "/growth-onboarding-reliability/static-1.2479",
      "sales-checkout-service-client": "/sales-checkout-service-client/static-1.1442",
      "commerce-billing-lib": "/commerce-billing-lib/static-1.10755",
      "commerce-products-lib": "/commerce-products-lib/static-1.1321",
      "commerce-shared-components-lib": "/commerce-shared-components-lib/static-1.11399",
      "crm_components": "/crm_components/static-3.82098",
      "crm_data": "/crm_data/static-4.82098",
      "customer-data-filters-ui": "/customer-data-filters-ui/static-1.29438",
      "customer-data-tracking": "/customer-data-tracking/static-1.4222",
      "dispatcher": "/dispatcher/static-1.104",
      "framework-data-table": "/framework-data-table/static-2.5831",
      "property-description-translator": "/property-description-translator/static-1.1362",
      "csstype": "/csstype/static-1.8",
      "fast-json-stable-stringify": "/fast-json-stable-stringify/static-1.4",
      "redux-undo": "/redux-undo/static-1.10",
      "hubspotter-http": "/hubspotter-http/static-1.2475",
      "feature-store-service-types": "/feature-store-service-types/static-1.2286",
      "growth-monetization-service-types": "/growth-monetization-service-types/static-1.2293",
      "upgrade-management-service-types": "/upgrade-management-service-types/static-1.2277",
      "permission-components": "/permission-components/static-1.2711",
      "getting-started-shared-tasks-store": "/getting-started-shared-tasks-store/static-1.6226",
      "diff-match-patch": "/diff-match-patch/static-1.7",
      "browser-eslint": "/browser-eslint/static-2.9",
      "framework-builder-read-service-client": "/framework-builder-read-service-client/static-1.1870",
      "framework-data-schema-quick-fetch": "/framework-data-schema-quick-fetch/static-1.3862",
      "inbound-db-meta-service-client": "/inbound-db-meta-service-client/static-1.1569",
      "persist-promise": "/persist-promise/static-1.1204",
      "payments-enrollment-embed-lib": "/payments-enrollment-embed-lib/static-1.13565",
      "apollo-link-hub-http": "/apollo-link-hub-http/static-2.2135",
      "general-store": "/general-store/static-6.20",
      "hubspotter-http-shared-msw-handlers": "/hubspotter-http-shared-msw-handlers/static-1.5255",
      "mobile-manifest-mixins": "/mobile-manifest-mixins/static-1.286",
      "platform-navigation-bootstrap": "/platform-navigation-bootstrap/static-1.9178",
      "ui-addon-react-router-dom": "/ui-addon-react-router-dom/static-1.6978",
      "growth-onboarding-feedback-components": "/growth-onboarding-feedback-components/static-1.3829",
      "customer-data-filters": "/customer-data-filters/static-1.43251",
      "highlight.js": "/highlight.js/static-1.5",
      "markdown-it": "/markdown-it/static-1.6",
      "react-table": "/react-table/static-1.15",
      "reporting-action-components": "/reporting-action-components/static-1.32068",
      "reporting-ui-components": "/reporting-ui-components/static-2.54843",
      "reporting-visualizations": "/reporting-visualizations/static-1.49638",
      "ui-addon-teams": "/ui-addon-teams/static-1.17830",
      "commerce-tools-ui-lib": "/commerce-tools-ui-lib/static-1.2777",
      "subscriptions-iframe-lib": "/subscriptions-iframe-lib/static-1.17533",
      "usage-tracker-session-replay": "/usage-tracker-session-replay/static-1.2633",
      "conversations-skeleton-state": "/conversations-skeleton-state/static-1.4774",
      "crm_universal": "/crm_universal/static-1.18976",
      "customer-data-content": "/customer-data-content/static-1.22059",
      "reporting-data": "/reporting-data/static-1.57557",
      "crm_schema": "/crm_schema/static-3.82133",
      "customer-data-email": "/customer-data-email/static-1.13812",
      "customer-data-filters-ui-msw-helpers": "/customer-data-filters-ui-msw-helpers/static-1.11869",
      "FormUrlGenerator": "/FormUrlGenerator/static-2.2190",
      "lodash": "/lodash/static-4.7",
      "crm-links": "/crm-links/static-1.3332",
      "customer-data-associations": "/customer-data-associations/static-1.4648",
      "redux-toolkit": "/redux-toolkit/static-1.7",
      "ecosystem-marketplace-core": "/ecosystem-marketplace-core/static-1.14351",
      "stripe-embedded-components": "/stripe-embedded-components/static-1.3193",
      "platform-infra-nav-components": "/platform-infra-nav-components/static-1.5576",
      "deep-diff": "/deep-diff/static-1.5",
      "dashboard-lib": "/dashboard-lib/static-1.71080",
      "reporting-snowflake": "/reporting-snowflake/static-1.46184",
      "share-with-third-party-component-lib": "/share-with-third-party-component-lib/static-1.5843",
      "highcharts": "/highcharts/static-8.69",
      "reporting-plugins": "/reporting-plugins/static-1.4525",
      "reporting-reports": "/reporting-reports/static-1.57247",
      "customer-data-rte": "/customer-data-rte/static-1.25573",
      "cv-backend-services": "/cv-backend-services/static-1.848",
      "hubspot-prosemirror": "/hubspot-prosemirror/static-1.9941",
      "prosemirror": "/prosemirror/static-1.13",
      "qrcode-generator": "/qrcode-generator/static-1.36",
      "invoices-ui-lib": "/invoices-ui-lib/static-1.45285",
      "amplitude-session-replay-browser": "/amplitude-session-replay-browser/static-1.6",
      "SalesContentIndexUI": "/SalesContentIndexUI/static-3.4860",
      "sequences-lib": "/sequences-lib/static-1.3757",
      "reporting-client-types": "/reporting-client-types/static-1.1125",
      "immutable-less": "/immutable-less/static-1.2034",
      "marketplace-ui-apps-core": "/marketplace-ui-apps-core/static-1.8913",
      "marketplace-ui-assets-core": "/marketplace-ui-assets-core/static-1.10319",
      "marketplace-ui-common": "/marketplace-ui-common/static-1.10375",
      "marketplace-ui-reviews": "/marketplace-ui-reviews/static-1.10337",
      "marketplace-ui-solutions-core": "/marketplace-ui-solutions-core/static-1.10350",
      "signup-constants": "/signup-constants/static-1.13792",
      "signup-ui-url-generator": "/signup-ui-url-generator/static-2.5310",
      "stripe-connect-js": "/stripe-connect-js/static-1.30",
      "onboarding-scopes": "/onboarding-scopes/static-1.3801",
      "redux-actions": "/redux-actions/static-3.7",
      "reporting-datasets-permissions-lib": "/reporting-datasets-permissions-lib/static-1.3021",
      "draft-content-plugins": "/draft-content-plugins/static-1.20853",
      "draft-convert": "/draft-convert/static-2.31",
      "draft-extend": "/draft-extend/static-2.46",
      "draft-js": "/draft-js/static-5.37",
      "draft-plugins": "/draft-plugins/static-2.17200",
      "knowledge-content-types": "/knowledge-content-types/static-1.6815",
      "sequences-client-types-lib": "/sequences-client-types-lib/static-1.3613",
      "developer-experience-shared-components": "/developer-experience-shared-components/static-1.7179",
      "marketplace-ui-client-types": "/marketplace-ui-client-types/static-1.7779",
      "ui-addon-integrations-directory-panel": "/ui-addon-integrations-directory-panel/static-2.5310",
      "ui-extensibility-client-types": "/ui-extensibility-client-types/static-1.1883",
      "react-window": "/react-window/static-1.13",
      "trellis-ecosystem": "/trellis-ecosystem/static-1.2616",
      "apps-service-types": "/apps-service-types/static-1.2066",
      "oauth-service-types": "/oauth-service-types/static-1.1857",
      "trellis-story-utils": "/trellis-story-utils/static-1.1894"
    },
    "project": "page-editor-ui",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};